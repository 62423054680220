.map {
  height: 400px;
}

body {
  padding-bottom: 0px !important;
}

.section-xlg {
  padding-top: 30px;
  padding-bottom: 30px;
}

.team__item {
  &:hover {
    .team__img {
      position: relative;
      z-index: 99;
      img {
        transform: translate(0%, 0%) scale(1.1);
        transition: all .5s;
        border-radius: 50%;
        overflow: hidden;
      }
    }
  }
}

.team__img {
  width: 250px;
  height: 250px;
  > img {
    max-width: 150%;
  }

}

.text-block {
  padding-top: 40px;
}

/* === Background Images === */
.bg-img {
  position: relative;
  overflow: hidden;
  @media (min-width: 992px) {
    height: 350px;
  }
  @media (max-width: 991px) {
    height: 250px;
  }
  @media (max-width: 767px) {
    height: 180px;
  }
  width: 100%;
  z-index: 1;
  .bg-filter {
    position: relative;
    height: 100%;
    width: 100%;
    background: fade(#000, 25%);
  }
  img {
    position: relative;
    left: 0 !important;
    right: 0 !important;
    //transform(translate(0,-20%));
    z-index: -1;
    height: auto;
    display: block;
    max-width: 100%;
  }
}

.showcase {
  margin-bottom: 0px;
}

// Call To Action Tweaks
//***********************
.info-board {
  margin-bottom: 0px;
}

a.btn.btn-lg.btn-primary {
  margin-top: 15px;
}

.info-board-primary {
  border-left-color: @brand;
}

//***********************

.block-header.alt > span {
  border-bottom: 2px solid #005393;
}

.page-topbar {
  h3 {
    border-bottom: 3px solid @brand;
  }
  .breadcrumb {
    > li {
      > a {
        color: @brand;
      }
    }
  }
}

.video-holder {
  margin-top: -18px;
}