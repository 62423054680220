/**
 * About-us.html
 */

/*** Features ***/

.feature__item {
  margin-bottom: 20px;
  text-align: center;
}
.feature__icon {
  margin-bottom: 15px;
  color: @brand-primary;
  font-size: @font-size-base * 2.5;
  .center-block();
}
.feature__body {
  overflow: hidden;
}
.feature__title {
  margin-top: 0;
}