/**
 * Carousel
 */

/*** General styles ***/

.carousel {

  &:hover {

    .carousel__control {
      opacity: .3;

      &:hover {
        opacity: .6;
      }
    }
  }
}

/*** Carousel controls ***/

.carousel__control {
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: opacity .3s;
}
.carousel__control_left {
  left: 10px; right: auto;
}
.carousel__control_right {
  left: auto; right: 10px;
}