.blog__item {
  margin-bottom: 20px;

  .blog__header {
    border: none;
    padding-bottom: 10px;

    .blog__title {
      a {
        color: @brand;
      }
    }
  }

  .blog__body {
    border-bottom: 1px solid #E9E9E9;
    padding-bottom: 25px;
  }
}

.blog__tags {
  li {
    a {
      &:hover {
        background-color: @brand;
      }

      &:focus {
        background-color: @brand;
      }
    }
  }
}

.WidgetHolder {
  h3 {
    margin-top: 30px;
    margin-bottom: 25px;
    border-bottom: 2px solid #005393;
    font-size: 17px;
    font-weight: 400;
    text-transform: uppercase;
    color: #555;
    width: 60%;
    padding-bottom: 10px;
  }
}

.post-title {
  color: #444444;
}

.thumbnail-small {
  display: block;
  padding: 1px;
  margin-bottom: 18px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #E9E9E9;
  border-radius: 0;
  transition: border 0.2s ease-in-out;
}

small {
  display: block;
  font-size: 12px;
}