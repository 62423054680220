/**
 * Progress Bars
 */

@progress-bg: @brand-gray-lightest;
@progress-border-radius: 0;
@progress-bar-bg: @brand-primary;

.progress,
.progress-bar {
  box-shadow: none;
  height: (@line-height-computed * 1.2);
}
.progress-bar {
  line-height: (@line-height-computed * 1.2);
  text-align: left;
  padding-left:15px;
}
