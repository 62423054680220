/**
 * 404.html
 */

.error-page {
  padding: 60px 0 80px;
}
.error-page__header {
  font-weight: 300;
  margin-bottom: 15px;

  + p {
    margin-bottom: 40px;
  }
}
.error-page__icon {
  width: 70px;
  height: 100px;
  margin-bottom: 40px;
  background: url(../img/page.svg) no-repeat top left / 100% 100%;
  .center-block();
}