.full-width {
  width: 100%;
  height: 100%;
  height: calc(100% - 1px);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  margin-top: -38px;
  img {
    width: 100%;
  }
  .center {
    vertical-align: middle;
    margin: 0 auto;
    display: flex;
    h2 {
      color: #ffffff;
      font-size: 55px;
      font-weight: 600;
    }
  }
}

.table_centered__cell {
  .h1 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.2;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px fade(black, 20%);
    color: white;

    @media (max-width: @screen-xs-max) {
      font-size: 36px;
    }
  }
}

.text-block {
  padding-top: 20px;
}

.img-border {
  padding: 10px;
  border: 1px solid #F0F0F0;
}

i.fa.fa-quote-left.fa-3x.pull-left {
  color: #f1f1f1;
  font-size: 85px;
}

.team__item {
  .team__img {
    display: flex;
    justify-content: center;
  }
  &:hover {
    .team__img {
      img {
        overflow: initial;
      }
    }
  }
}

.btn-dark {
  background-color: #444444;
  border-color: #444444;
  color: #e0e0e0;
  &:hover {
    background-color: #545454;
    border-color: #4e4e4e;
    color: #e0e0e0;
  }
  &:active {
    background-color: #545454;
    border-color: #4e4e4e;
    color: #e0e0e0;
    &:hover {
      background-color: #545454;
      border-color: #4e4e4e;
      color: #e0e0e0;
    }
  }
  &:focus {
    background-color: #545454;
    border-color: #4e4e4e;
    color: #e0e0e0;
  }
}

.btn-light {
  background-color: #fffffe;
  border-color: #fffffe;
  color: #444444;
  &:hover {
    background-color: #c1c1c1;
    border-color: #e8e8e8;
    color: #444444;
  }
  &:active {
    background-color: #c1c1c1;
    border-color: #e8e8e8;
    color: #444444;
    &:hover {
      background-color: #c1c1c1;
      border-color: #e8e8e8;
      color: #444444;
    }
  }
  &:focus {
    background-color: #c1c1c1;
    border-color: #e8e8e8;
    color: #444444;
  }
}

.video-section {
  .video {
    .embed-responsive {
      margin-bottom: 35px;
    }
  }
  .col-md-3 {
    height: 285px;
  }
  .col-sm-6 {
    height: 345px;
  }
}