.navbar-brand {
  padding: 1.5px 1px;
  > img {
    z-index: 1000;
    position: absolute;
    width: 155px;
    @media (min-width: @screen-xs-min) {
      width: auto;
    }
    @media (min-width: @screen-sm-min) {
      width: 160px;
    }
    @media (min-width: @screen-md-min) {
      width: auto;
    }
  }
  @media (min-width: @screen-xs-min) {
    padding: 18.5px 15px;
  }
  @media (min-width: @screen-sm-min) {
    padding: 1.5px 1px;
  }
  @media (min-width: @screen-md-min) {
    padding: 18.5px 15px;
  }
}