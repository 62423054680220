form {
  padding: 20px 0;
  fieldset {
    div.field {
      label {
        font-weight: normal;
        color: #bdbdbd;
      }
      div.middleColumn {
        input, select, textarea {
          border-radius: 0;
          border: 1px solid #cccccc;
          padding: 10px 16px;
          width: 100%;
          margin-bottom: 10px;
          -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
          -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
          transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
          &:focus {
            outline: none;
            border: 1px solid @brand-dark;
          }
        }
        input {

        }
        select {

        }
        textarea {

        }
      }
    }
  }
  div.Actions {
    input[type="submit"] {
      &:extend(.btn);
      &:extend(.btn-xlg);
      background-color: @brand;
      border-color: @brand;
      border-radius: 0;
      color: #ffffff;
      padding: 10px 16px;
      font-size: 13px;
      margin-bottom: 15px;
    }
  }
}

// MailChimp Overrides
//---------------------------

#mc_embed_signup {
  background: transparent !important;
  #mc_embed_signup_scroll {
    input.email {
      width: 100%;
      float: left;
      @media (min-width: @screen-xs-min) {
        width: 190px !important;
      }
      @media (min-width: @screen-lg-min) {
        width: 250px !important;
      }
    }
  }
  input.button {
    width: 100%;
    float: left;
    margin-left: 0;
    @media (min-width: @screen-xs-min) {
      width: 170px;
      margin-left: 5px;
    }
    @media (min-width: @screen-md-min) {
      width: 100%;
    }
  }
}

