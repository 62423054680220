/**
 * Scrolltopcontrol
 */

.backtotop {
  background: white;
  padding: 10px 15px;
  font-size: 18px;
  color: @text-color;
  border-radius: @border-radius-small;
  box-shadow: 0 0 20px fade(black,20%);
}