/**
 * Pagination
 */

/*** Pagination ***/

@pagination-border: @brand-gray-lighter;

.pagination {

  > li,
  &.pagination-lg > li,
  &.pagination-sm > li {

    &:first-child, &:last-child {

      > a {
        border-radius: 0;
      }
    }
  }
}