.light {
  background-color: @theme-tertiary;
}

.dark {
  background-color: @theme-primary;
  color: #fff2e8;
}

.brand {
  background-color: @brand;
  color: #e0e0e0;
  .block-header {
    color: #e0e0e0;
  }
}

.blue {
  background-color: @brand-light;
  color: #e0e0e0;
}

.dark-blue {
  background-color: @brand-dark;
  color: #e0e0e0;
}