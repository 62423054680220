/**
 * Footer
 */

footer {
  position: absolute;
  bottom: 0; left: 0; 
  width: 100%;
  padding: 40px 0;
  background-color: @footer-background;
  font-size: @font-size-base;
  color: @footer-text;
}
.footer__header {
  margin-bottom: 20px;
  font-size: @font-size-base;
  font-weight: normal;
  text-transform: uppercase;
  color: @footer-text;
}
.footer__content {
  list-style: none;
  padding-left: 0;

  > li {
    
    > a {
      display: block;
      padding: 2.5px 0;
      color: @footer-text;

      &:hover {
        color: @footer-links;
        text-decoration: none;
      }
    }
  }
}
form.footer__form {
  margin-top: 20px;

  input {
    border: 0;
    border-radius: 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
.footer__hr {
  margin: 40px 0;
  border-color: fade(white,15%);
}
.footer__social {
  list-style: none;
  padding-left: 0;
  margin-top: 20px;

  > li {
    display: inline-block;

    > a {
      @size: 25px;
      display: block;
      width: @size; height: @size; line-height: @size;
      background-color: @footer-background;
      color: @footer-text;
      text-align: center;

      &:hover {
        background-color: @footer-links;
      }
    }
  }
}