/**
 * Ui Elements
 */

/*** Ui Categories ***/

#ui-categories {

  &.affix {
    position: relative;
  }
}
@media(min-width: @screen-sm-min) {
  #ui-categories {

    &.affix {
      position: fixed !important;
      top: 20px;
    }
  }
}

/*** UI Buttons block ***/

.ui-buttons {

  .btn {
    margin-bottom: 5px;
  }
}