/**
 * Info Board
 */

.info-board {
  padding: 10px 20px;
  margin-bottom: 20px;
  border-left: 4px solid @brand-gray;
  background-color: fade(@brand-gray,10%);
}
.info-board-primary {
  background-color: fade(@brand-primary,10%);
  border-left-color: @brand-primary;
}