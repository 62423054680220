body {
  font-family: 'Raleway', sans-serif;
  h1, h2, h3, h4, h5, h6 {
    color: @font-primary;
    font-family: @font-family;
  }
}

//Font Tweaks from CMS
//--------------------------

.brand-blue {
  color: @brand;
}

//--------------------------

p {
  font-size: 15px;
}

.block-header.alt {
  font-size: 19px;
}

a {
  color: @brand-light;
  text-decoration: none;
}