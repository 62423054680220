//@color1:        @blue400;
//@color2:        @blueGrey900;
//@color3:        @blueGrey800;
//@color4:        @blueGrey50;
//@color5:        @blueGrey200;
//@color6:        @blueGrey100;
//@color7:        @customblueGrey;

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600');

@theme-primary: #323233;
@theme-secondary: #005aab;
@theme-tertiary: #d9ebf2;

@font-size: 13px;
@font-family: 'Poppins', sans-serif;

@font-primary: @theme-primary; // Headings (@theme-primary)
@font-secondary: rgba(0,0,0,.8); // Body
@font-tertiary: @theme-secondary; // Links (@theme-secondary)

@font-primary-alt: rgba(255,255,255,.8);
@font-secondary-alt: rgba(255,255,255,.8);
@font-tertiary-alt: fade(@theme-secondary, 80%);

@nav-background: #FFFFFF; // Nav (@theme-primary)
@nav-links: @theme-secondary;
@nav-link-hover: @theme-secondary;

@topbar-background: #f2f9fd;
@topbar-links: @theme-secondary;

@footer-background: @theme-primary;
@footer-text: #E9E9E9;
@footer-links: fade(@theme-secondary, 80%);

