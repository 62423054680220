/**
 * Modals
 */

.modal-content {
  border-radius: 0;
}
.modal-header {
  background-color: @brand-gray-lightest;

  > h4 {
    font-size: @font-size-base;
    font-weight: 600;
    text-transform: uppercase;
  }
}