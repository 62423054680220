/**
 * Contact.html
 */

/*** Contact form ***/

#form_message {
 display: none;
 position: fixed;
 top: 0; left: 0; right: 0;
 border-radius: 0;
 text-align: center;
 z-index: 999999;
}
#form_sendemail {

  .help-block {
    display: none;
  }
  .has-error {

    .form-control {
      border-color: red;
    }
    .help-block {
      display: block;
      color: red;
    }
  }
}