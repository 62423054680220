/**
 * Navs
 */

/*** Navs: Tabs ***/

.nav-tabs {
  margin-bottom: @line-height-computed;

  &.nav-justified > li,
  > li {

    > a {
      border-radius: 0;
    }
  }
}